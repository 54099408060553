import { IBillingPeriod } from '@core-ports/outputs/BillingPeriod';
import { gql } from 'apollo-angular';

export type IQueryGetBillingPeriod = {
	GetBillingPeriod: IBillingPeriod;
};

export const graphQLBillingPeriodSchemaSchema = `
id
start_date
expiration_date
issue_date
state
`;

export const query_get_billing_period = gql`
  query GetBillingPeriod($filter: Object!) {
    GetBillingPeriod(filter: $filter) {
      id
      start_date
      expiration_date
      state
    }
  }
`;
